<template>
	<div class="page page-services">
		<section class="section">
			<CompHero
				:propTextHero="[ 'Leistungspakete' ]"
				propHeadlineLevel="h1"
				:propApplyAnimation="false"
			/>
			
			<p class="subheadline-explanatory typography-editorial-small">
				Wir gehen Dinge gerne ganzheitlich an.
				Das erleichtert die Koordination, verbessert die Umsetzung und spart Ressourcen.
			</p>
		</section>
		
		<CompServicePackage
			v-for="(service, index) in services_packages"
			:propService="service"
			class="max-width"
			:key="`${service}-key-${index}`"
		/>
		
		<CompCallToAction />
		
		<CompFooter />
	</div>
</template>

<script>
import CompHero from '../components/CompHero.vue'
import CompButtonCopyContent from '../components/CompButtonCopyContent.vue'
import CompServicePackage from '../components/CompServicePackage.vue'
import CompCallToAction from '../components/CompCallToAction.vue'
import CompFooter from '../components/CompFooter.vue'

import { createSEOMeta } from "@/helpers/helperMetainfo.js";

const metaInfos = {
	title: `Leistungen • Grafik-Design Studio Eureka!`,
	description: `Das Grafik-Design Studio Eureka! geht Projekte ganzheitlich an. Deshalb arbeiten wir in Leistungspaketen, um eine reibungslose Koordination, eine verbesserte Umsetzung und maximale Ressourceneffizienz zu gewährleisten.`,
	image: ``,
	robots: `index, follow`
}

export default {
	name: 'PageServices',

    metaInfo: createSEOMeta(metaInfos.title, metaInfos.description, metaInfos.image, metaInfos.robots),
	
	components: {
		CompHero,
		CompButtonCopyContent,
		CompServicePackage,
		CompCallToAction,
		CompFooter
	},
	
	data () {
		return {
			services_packages: {
				identity: {
					background_color: `#E8B4C1`,
					services_package: `Identität`,
					id: `identitaet`,
					headline: `Identität<br>
						<s>Branding</s>`,
					subheadline: `<p>
							Marken verkörpern Werte, Haltungen, Eigenheiten und Geschichten.
						</p>
						<p>
							Aus dieser Substanz destillieren wir Design, Sprache und Handlungen zu einer Markenidentität.
						</p>
					`,
					packages: [
						{
							sectionName: ``,
							services: [
								{
									subject: `Identitätsfindung Workshop`,
									detailed_services: [
										`Analyse`,
										`Start with Why`,
										`Werte`,
										`Merkmale`,
										`Ziele`,
										`Zielgruppe`,
									],
									description: `Identität muss nicht erfunden sondern <i>ge</i>funden werden. Wir arbeiten sie aus einer Vielzahl von Werten, Haltungen, Eigenheiten und Geschichten heraus, und schärfen sie im Prozess.
									<br><br>
									In den nächsten Schritten entwickeln wir daraus dann Design, Sprache, Botschaften, Handlungen und vieles mehr.`
								},
								{
									subject: `Markenstrategie Workshop`,
									description: `Jede Marke hat Stärken und Schwächen. Im Markenstrategie-Workshop entscheiden wir, auf welche unserer Vorteile und Talente wir setzen.`
								},
								{
									subject: `Moodboard/Visuelle Richtung`,
									description: `Oft sind Worte missverständlich, und Vorstellungen gehen auseinander.
									<br><br>
									Um eine gemeinsame visuelle und begriffliche Grundlage zu schaffen, erarbeiten wir anhand der Identität und Markenstrategie Moodboards und Skizzen. Diese dienen im nächsten Schritt als Basis für das Corporate Design.`
								},
								{
									subject: `Corporate Design`,
									detailed_services: [
										`Grafik-Design`,
										`Logo`,
										`Schrift`,
										`Farben`,
										`Formen`,
										`Bildsprache`,
										`Visitenkarten`,
										`Briefpapier`
									],
									description: `Wir alle wollen uns von der Konkurrenz abheben.
									<br><br>
									Mit dem Corporate Design schaffen wir einen einmaligen und wiedererkennbaren Look für unsere Marke. Dadurch differenzieren wir uns von der Konkurrenz und verschaffen uns einen Marktvorteil.`
								},
								{
									subject: `Markenhandbuch`,
									detailed_services: [
										`Identität`,
										`Markenstrategie`,
										`Corporate Design`
									],
									description: `Eine Marke will (von ihren Markenbotschafter*innen) gepflegt werden. Dafür verfassen wir das Markenhandbuch: ein Nachschlagewerk für alle markenrelevanten Bausteine, die wir gemeinsam erarbeitet haben.`
								},
							]
						}
					]
				},
				website: {
					background_color: `#96C2A4`,
					services_package: `Webseite`,
					id: `webseite`,
					headline: `Webseite`,
					// subheadline: `
					// 	<p>
					// 		Bei uns steht WWW steht für »Wo«, »Wie« und »Was«.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe besteht darin, die Frage »Wo und wie finde ich was?« mithilfe einer Webseite zu beantworten.
					// 	</p>
					// <p>
					// 	Webseitenbesucher*innen suchen Antworten auf die WWW-Fragen: »Wo«, »Wie« und »Was«.
					// </p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Webseitenbesucher*innen suchen meist Antworten auf die WWW-Fragen: »Was ist es«, »Wie ist es« und »Wo ist es«.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es folglich diese Fragen mithilfe einer kompakten Webseite zu beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Besucher*innen suchen Antworten auf die WWW-Fragen: ›<i>Was ist es?‹</i>, ›<i>Wie funktioniert es?‹</i> und ›<i>Wo finde ich es?‹</i>.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es diese Fragen mithilfe einer Webseite zu beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Webseitenbesucher*innen suchen Antworten auf die <i>WWW</i>-Fragen: <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es diese Fragen mithilfe einer Webseite zu beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Webseiten sind fleißige, immer verfügbare Markenbotschafter.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es, sie mit den wichtigsten Infos und Antworten auf auszustatte.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Wir betrachten Websites als Web<i>sights</i>. Entsprechend sollen die Besucher*innen nach dem WebSightSeeing einen bleibenden Eindruck
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es, sie mit den wichtigsten Infos und Antworten auf auszustatte.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Wir betrachten Webseiten als öffentlich Räum.
					// 	</p>
					// 	<p>
					// 		Ihr Besucher*innen suchen meist Antworten auf die essentiellen <i>WWW</i>-Fragen: <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es diese Fragen mithilfe der Webseite zu beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Unsere Webseiten sind öffentlich und immer zugängliche Schauräum.
					// 	</p>
					// 	<p>
					// 		Ihre Besucher*innen stellen meist die <i>WWW</i>-Fragen: <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es diese Fragen schnell und einfach beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Webseiten sind Markenschauräume und haben Besucher*innen.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es ihre essentiellen <i>WWW</i>-Fragen <i>Was</i>, <i>Wie</i> und <i>Wo</i> mit der Webseite zu beantworten.
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Besucher*innen suchen Antworten auf die <i>WWW</i>-Fragen: <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
					// 	</p>
					// 	<p>
					// 		Unsere Aufgabe ist es diese Fragen mithilfe der Webseite zu beantworten.
					// 	</p>
					// `,

					subheadline: `
						<p>
							Besucher*innen suchen Antworten auf die <i>WWW</i>-Fragen: <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
						</p>
						<p>
							Unsere Aufgabe ist es diese Fragen in Form einer Webseite zu beantworten.
						</p>
					`,

					// subheadline: `
					// 		Webseiten sind öffentliche Schauräume mit vielen Besucher*innen

					// 		Unsere Aufgabe ist es die essentiellen WWW-Fragen Wo wo wo zu auf der Webseite beantworten
					// 	</p>
					// `,

					// subheadline: `
					// 	<p>
					// 		Eine gute Webseite beantwortet die WWW-Fragen: <i>WWW</i>-Fragen <i>Was</i>, <i>Wie</i> und <i>Wo</i>.
					// 	</p>
					// `,
					packages: [
						{
							sectionName: `Basispaket`,
							services: [
								{
									subject: `Content und Strategie Workshop`,
									// description: `
									// 	Gemeinsam mit unseren Kund*innen definieren wir die Grundpfeiler der Webseite.
									// 	<br>
									// 	<br>
									// 	Wir adressieren zentrale Fragen wie: »Welche Ziele verfolgt die Seite?«, »Welche Informationen soll sie bereitstellen?« oder »Wen sie die Seite erreichen?«, und erarbeiten erste Ideen, Inhalte, Strukturen, und Funktionen als Basis für die weiter Gestaltung.
									// `
									description: `
										Gemeinsam mit unseren Kund*innen adressieren wir zentrale Fragen wie: »Welche Ziele verfolgt die Seite?«, »Welche Informationen soll sie bereitstellen?« oder »Wen sie die Seite erreichen?«.
										<br>
										<br>
										Daraus erarbeiten wir erste Ideen, Inhalte, Strukturen, und Funktionen und definieren die Grundpfeiler der Webseite.
									`
								},
								{
									subject: `Wireframe/Prototyp`,
									// description: `
									// 	Das Wireframe ist ein Werkzeug mit dem wir die Struktur und das grundlegende Layout der Webseite visualisieren.
									// 	<br>
									// 	<br>
									// 	In einem frühen Projektstadium und ohne großen Gestaltungs- und Programmieraufwand erstellen wir einen  Prototyp, den wir auf die gewünschten Inhalte, Strukturen, und Funktionen testen können.
									// 	<br>
									// 	<br>
									// 	Diese Phase erlaubt es uns schnell und einfach auf Probleme zu reagieren und diese früh zu lösen.
									// `,

									// description: `
									// 	Das Wireframe ist ein Werkzeug, mit dem wir die grundlegenden Ideen, Inhalte, Strukturen, und Funktionen der Webseite visualisieren.
									// 	<br>
									// 	<br>
									// 	Ohne großen Gestaltungs- und Programmieraufwand erstellen wir einen Prototyp, den wir mit unseren Kund*innen auf die erarbeiteten Konzepte testen.
									// 	<br>
									// 	<br>
									// 	Mit dieser Methode können wir schnell und einfach auf Probleme reagieren, und diese noch vor den aufwendigen Ausarbeitungsschritten oder gar Veröffentlichung lösen.
									// `

									// description: `
									// 	Das Wireframe ist ein Werkzeug, mit dem wir die im Workshop erarbeiteten Grundpfeiler visualisieren und testen.
									// 	<br>
									// 	Ohne großen Gestaltungs- und Programmieraufwand erstellen wir Prototypen, die wir gemeinsam mit unseren Kund*innen auf die erarbeiteten Konzepte testen.
									// 	<br>
									// 	<br>
									// 	Mit dieser Methode können wir schnell und einfach auf Probleme reagieren und diese bereits vor den aufwendigen Ausarbeitungsschritten oder der Veröffentlichung lösen.
									// `

									description: `
										Das Wireframe ist ein Werkzeug, mit dem wir – schnell und einfach – die im Workshop erarbeiteten Grundpfeiler abbilden und testen.
										<br>
										<br>
										Gemeinsam mit unseren Kund*innen können wir damit auf Probleme reagieren und diese vor den aufwendigen  Ausarbeitungsschritten und der Veröffentlichung lösen.
									`
								},
								{
									subject: `Webdesign`,
									// description: `Bevor die Webseite programmiert und veröffentlich wird, erarbeiten wir ein detailliertes Webdesign zur Veranschaulichung der Handy- und Laptopansichten.<br>
									// <br>
									// Im Webdesign lassen sich Änderungen und Wünsche noch schneller und leichter als Basis für die Programmierung adaptieren.
									// `

									// description: `
									// 	Vor der aufwendigen Programmierung und Veröffentlichung der Webseite entwickeln wir ein detailliertes Webdesign. Dieses zeigt eine Vorschau der Webseite in allen Details, inklusive Texten und Bildern.
									// 	<br>
									// 	<br>
									// 	Im Webdesign-Prozess lassen sich Änderungen und Wünsche noch schnell und leicht adapieren. So schaffen wir eine ausgestaltete Basis für die anschließende Programmierung schafft.
									// `

									// description: `
									// 	Vor der aufwendigen Programmierung und Veröffentlichung der Webseite entwickeln wir ein detailliertes Webdesign, indem wir Änderungen und Wünsche noch relativ schnell und leicht adapieren können.
									// 	<br>
									// 	<br>
									// 	Dieses zeigt eine Vorschau der Webseite in allen Details, inklusive Texten und Bildern. Damit schaffen wir eine ausgestaltete Basis für die anschließende aufwendige Programmierung.
									// `

									description: `
										Vor der aufwendigen Programmierung und Veröffentlichung der Webseite entwickeln wir ein detailliertes Webdesign, das eine Vorschau der Webseite in allen Details zeigt. Das geht einher mit der Content Creation.
										<br>
										<br>
										In diesem Schritt können Änderungen und Wünsche noch relativ schnell und leicht angepasst werden. Damit schaffen wir eine ausgestaltete Basis für die anschließende aufwendige Programmierung.
									`
								},
								{
									subject: `Content Creation`,
									detailed_services: [
										`Text`,
										`Fotos`,
										`Vidoes`,
										`Illustrationen`
									],
									description: `
										Content schafft eine emotionale Bindung zwischen der Marke und ihren Nutzer*innen. Durch Geschichten, Bilder und Videos können Marken tiefergehende Gefühle und Identifikation erzeugen.
                                        <br>
                                        <br>
                                        Um einen stringenten Auftritt zu schaffen, geht die Content Creation Hand in Hand mit dem Webdesign. Dabei eruieren wir, wann vorhandenes Material in das Design integriert werden kann, und wann frischer, maßgeschneiderter Content notwendig ist.
                                        <br>
                                        <br>
                                        Dieser Prozess stellt sicher, dass die Webseite nicht nur optisch, sondern auch inhaltlich hervorsticht und die Marke authentisch repräsentiert.
									`,
									// obligation: `Bedarfsorientiert`
								},
								{
									subject: `Basis-Programmierung`,
									detailed_services: [
										`Responsiv`,
										`Barrierefrei`,
										`DSGVO-Konform`,
										`HTML`,
										`JavaScript`,
										`CSS`,
									],
									description: `
										Die programmatische Umsetzung der Webseite, inklusive der Veröffentlichung.
										<br>
										<br>
										In diesem Schritt machen wir aus der bildhaften Abbildung des Webdesigns vollwertige Webseiten, die von all den Besucher*innen mit all ihren individuellen Bedürfnissen, Browsern und Bildschirmgrößen aufgerufen werden können.
									`
								},
								{
									subject: `Content Management System (CMS)`,
									description: `
										Eine Webseite ist nie <i>fertig</i>. Sie entwickelt sich zusammen mit Ihrer Marke weiter. Um diese Änderungen auf der Webseite widerzuspiegeln, integrieren wir ein Content Management System (CMS).
										<br>
										<br>
										Neue Inhalte können damit selbstständig, schnell und ohne Programmierkenntnisse von unseren Kund*innen editiert und veröffentlicht werden. Dies gibt Kund*innen jederzeit die volle Kontrolle über ihre Webseite. Besonders für Seiten mit häufigen und umfangreichen Aktualisierungen (mindestens monatlich), wie Blogs, News-Seiten oder Online-Shops, ist dies von großem Vorteil.
									`
								},
							]
						},
						{
							sectionName: `Optionale Zusätze`,
							services: [
								{
									subject: `Coming Soon Seite`,
									description: `
										Bevor die vollwertige Webseite fertiggestellt ist, veröffentlichen wir eine schnelle Platzhalter-Webseite. Diese enthält das Logo, Kontaktinformationen und einen Coming-Soon-Text.
									`,
									obligation: `Optional`
								},
								{
									subject: `Hosting-Service`,
									detailed_services: [
										`Hostingpaket`,
										`Domain`,
										`E-Mail-Adressen`
									],
									description: `
										Die Einrichtung des Hostings, der Domain und der E-Mail-Accounts erfordert oft technisches Vorwissen und nimmt viel Zeit in Anspruch.
										<br>
										<br>
										Als Teil unseres Services übernehmen wir gerne die Verantwortung und Organisation, und kümmern uns um Updates und auftretende Probleme.
									`,
									obligation: `Optional`
								},
								// {
								// 	subject:
								// 		`Fremdleistungen wie Fotografie, Videos oder Illustrationen`,
								// 	// detailed_services: [
								// 	// 	`Fotografie`,
								// 	// 	`Videofragie`,
								// 	// 	`Illustration`
								// 	// ],
								// 	description:
								// 		`Oft gibt es schon Fotos, Videomaterial oder Illustrationen die auf der Webseite verwendet werden können. Sollte das nicht der Fall sein, arbeiten wir mit einige Kreativen zusammen, die diese Leistungen zusteuern.
								// 	`,
								// 	obligation: `Optional`
								// }
							]
						}
					]
				},
				creative_consulting: {
					isComingSoon: true,
					background_color: `#FDF396`,
					services_package: `Kreativberatung`,
					id: `kreativberatung`,
					headline: `Kreativberatung`,
					subheadline: `<p>
							Das wichtigste Werkzeug bei der Ideenfindung ist:
						</p>
						<p>
							Der Mistkübel.*
						</p>
						<p>
							*(Design Thinking kann auch ganz nützlich sein.)
						</p>
					`
				}
			}
		}
	},

	computed: {
		computedPhone () {
			return this.$store.state.phone
		},

		computedMail () {
			return this.$store.state.mail
		}
	}
}

</script>